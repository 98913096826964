const sectionSortOrder = {
  'how-to-guides': [
    'studio-fundamentals',
    'quick-start',
    'managing-content',
    'studio-development',
    'backend-development',
    'frontend-development',
    'monitoring-and-logging',
    'security'
  ],
  'protected': [
    'contact-center-and-analytics-studio',
    'application-reference',
    'release-notes'
  ],
  'analytics': [
    'dashboards',
    'managing-listing-panels',
    'managing-aggregation-panels',
    'reference'
  ]
}

export default sectionSortOrder
