import styled from 'styled-components'
import Title from "./Title";
import React from 'react'
import Node from "./Node";
import Accordion from "./Accordion";
import {useNavigationTree} from "../../hooks/use-navigation";
import Link from "gatsby-link";
import {useLocation} from '@gatsbyjs/reach-router'
import {Book} from "react-feather";
import clsx from 'clsx'

const Style = styled.div`
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  padding: 48px 15px 48px 15px;
  position: fixed;
  max-height: calc(100vh - 52px - 70px);
  max-width: 18rem;
  top: 122px;

  &.fullscreen {
    position: revert;
    max-height: revert;
    max-width: revert;
    top: revert;
  }

  > div + div {
    margin-top: 10px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,255,0,0.3);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,255,0,0.5);
  }

  a, a:hover, a:visited, a:active {
    text-decoration: none;
  }
`;



const Sidebar = ({ fullscreen, contentArea }) => {
  const navigationTree = useNavigationTree();
  const location = useLocation();
  const HOWTO_GUIDES_SLUG = contentArea

  const rootSlug = location.pathname.split('/').slice(1, 2)[0];
  const navigation = navigationTree[rootSlug]
  && navigationTree[rootSlug].tree
  && navigationTree[rootSlug].tree.length > 1
    ? navigationTree[rootSlug]
    : navigationTree[HOWTO_GUIDES_SLUG];

  if (!navigation || !navigation.tree || navigation.tree.length <= 1) {
    return <Style>&nbsp;</Style>
  }

  return (
    <Style className={clsx({ fullscreen })}>
      <>
        <Title icon={<Book/>}>{navigation.title}</Title>
        {navigation.tree.map((item, index) => {
          if (!item.hasChildren) {

            return <Node key={index}
                         onClick={item.onClick}>
              <Link to={`/${item.slug}/`} activeClassName="active">{item.label}</Link>
            </Node>

          } else if (item.slug.split("/").length>2) {
            return <i></i>
          }
          else {

            return <Accordion key={index}
                              label={item.label}
                              slug={item.slug}
                              navigation={navigation}>{item.children}
            </Accordion>

          }
        })}
      </>
    </Style>
  )
}

export default Sidebar;
