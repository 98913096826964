import {wrapRootElement as rootWrapper, wrapPageElement as pageWrapper} from './root-wrapper'
import { setConfiguration } from 'react-grid-system';
import { initialize } from './src/utils/zendesk'

// Wraps the root element in a component
export const wrapRootElement = rootWrapper;

// Wraps each page element into a component
export const wrapPageElement = pageWrapper;

const addScript = ({ url, dataset = {}, defer = false, id }) => {
  const script = document.createElement("script")
  script.src = url
  if (!defer) {
    script.async = true
  } else {
    script.defer = true
  }
  script.type = "text/javascript"

  if (id) {
    script.id = id
  }

  Object.keys(dataset).forEach((key) => {
    script.dataset[key] = dataset[key]
  })
  document.body.appendChild(script)
}


export const onClientEntry = () => {

  // Initialise Cookie Consent & Zendesk
  window.onload = () => {
    addScript({
      url: "https://consent.cookiebot.com/uc.js",
      dataset: { cbid: "2f0bde22-a848-45b4-9af6-d69e0649d7a6" },
      id: "Cookiebot"
    })

    addScript({
      url: "https://static.zdassets.com/ekr/snippet.js?key=b08139c0-1357-4d46-a84f-bd77953de9f0",
      id: 'ze-snippet',
      defer: true
    })

    // Initialize Zendesk
    initialize()

  }

  // Set isOutsideEU dimension
  const isOutsideEU = typeof window.Cookiebot !== "undefined" ? (window.Cookiebot.isOutsideEU ? "non-EU" : "EU") : null;
  if (window.gtag && isOutsideEU) {
    window.gtag('set', 'dimension16', isOutsideEU);
  }

  // Set grid configuration
  setConfiguration({
    maxScreenClass: 'xl',
    containerWidths: [ 740, 740, 960, 1140, 1540 ]
  });
}
