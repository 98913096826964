import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { ChevronDown, UserCheck, UserX } from 'react-feather'
import Logo from './Logo'
import { useMatch } from '@gatsbyjs/reach-router'
import { linkToDocumentation, linkToDownloads } from '../../utils/links'
import useConfig from '../../hooks/use-config'
import SearchField from '../Search/SearchField'
import clsx from 'clsx'
import useIsUserLoggedin from '../../hooks/use-is-user-logged-in'
import Burger from './Burger'
import LogoSmall from './LogoSmall'
import Hidden from '../Responsive/Hidden'
import Visible from '../Responsive/Visible'

const StyledHeader = styled.header`

  overflow: hidden;
  -webkit-font-smoothing: antialiased;

  width: 100%;
  z-index: 10;

  .first {
    position: fixed;
    width: 100vw;
    background-color: #3f3f3f;
    min-height: 70px;
    height: 70px;
    z-index: 20;

    display: flex;
    align-items: center;
    padding: 0 45px;
    justify-content: space-between;

    a {
      line-height: unset;
    }

    .left {
      flex-grow: 1;
      justify-content: space-between;
    }

    .user-status {
      position: relative;
      cursor: pointer;

      .popover {
        position: absolute;
        width: auto;
        background-color: rgba(0, 0, 0, .6);
        color: #fff;
        padding: 12px 24px;
        border-radius: 8px;
        right: -24px;
        white-space: nowrap;
        transition: opacity ease-in-out .3s;
        opacity: 0;
        pointer-events: none;

        &.open {
          opacity: 1;
          pointer-events: unset;
        }

        svg {
          position: absolute;
          top: -12px;
          right: 30px;
          color: rgba(0, 0, 0, .6);
          height: auto;
        }

        button {
          border: none;
          background: transparent;
          text-decoration: underline;
          color: #ffffff;
          cursor: pointer;
        }
      }
    }

    svg {
      color: #fff;
      height: 33px;
    }

    .navigation {
      text-align: right;
      font-family: "DM Sans", sans-serif;
      margin-right: 2rem;

      ul {
        list-style-type: none;
        display: flex;
        color: #fff;

        li + li {
          padding-left: 20px;
        }
      }

      a {
        font-weight: 500;
        padding: 4px 0 7px;
        transition: border-bottom-color .6s ease-out;
        border-bottom: solid 4px transparent;
      }

      a:hover {
        border-bottom: solid 4px #fff;
      }

      a.active {
        border-bottom: solid 4px #fff;
      }
    }

  }

  .second {
    position: fixed;
    width: 100vw;
    top: 70px;
    background-color: #006CAE;
    display: flex;
    justify-content: space-between;
    z-index: 10;

    min-height: 52px;
    height: 52px;

    padding: 0 24px;

    &.hidden {
      display: none;
    }
  }

  @media only screen and (max-width: 768px) {
    .full-width {
      flex-grow: 1;

      > div {
        width: 100%;
      }
    }
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      margin: 0;
    }
  }

  .buttons, .left, .right {
    display: flex;
    flex-direction: row;
    align-items: center;

    li + li {
      padding-left: 12px;
    }
  }

  .buttons {
    margin: 0;
    padding-right: 24px;

    a, a:active, a:visited, a:hover {
      font-family: "DM Sans", sans-serif;
      font-size: 14px;
      font-weight: 500;
      color: #fff;
      text-decoration: none;
      height: 40px;
      padding: 4px 0 4px;
      transition: border-bottom-color .6s ease-out;
      border-bottom: solid 2px transparent;

      &:hover {
        border-bottom: solid 2px #fff;
      }
    }
  }

  .select-wrapper {
    position: relative;
    color: #fff;
    cursor: pointer;
    margin: -5px 24px 0 0;

    select {
      background: none;
      border: none;
      border-bottom: solid 1px rgba(255, 255, 255, .6);
      color: #fff;
      font-size: 16px;
      appearance: none;
      cursor: pointer;
      padding: 0 20px 0 0;

      option {
        color: #3f3f3f;
      }
    }

    svg {
      position: absolute;
      top: 2px;
      right: 0;
      pointer-events: none;
    }
  }

  .logo > a > div {
    display: flex;
  }
`

const LoginState = ({ userLoggedIn, loginEnabled, triggerLogin }) => {
  const [ isPopoverOpen, setIsPopoverOpen ] = React.useState(false)
  const UserLoggedInPopover = () => userLoggedIn ? (
    <div>
      You're logged in.&nbsp;&nbsp;&nbsp;<span role={'img'} aria-label={'Hooray!'}>🎉</span>
    </div>
  ) : (
    <div>
      You're not logged in. <button onClick={() => triggerLogin()}>Login</button><button onClick={() => {window.zE('webWidget', 'show'); window.zE('webWidget', 'open')}}>Register</button>
    </div>
  )

  const container = React.createRef()
  const handleClick = (event) => {
    if (container.current && !container.current.contains(event.target)) {
      if (isPopoverOpen) setIsPopoverOpen(false)
    }
  }

  // Add outside click handler
  React.useEffect(() => {
    document.addEventListener('mousedown', handleClick)
    return () => document.removeEventListener('mousedown', handleClick)
  })

  if (!loginEnabled) return ''

  return (
    <>
      {/* eslint-disable */}
      <div className={'user-status'} onClick={() => setIsPopoverOpen(v => !v)} ref={container}>
        {userLoggedIn ? <UserCheck alt={'You are logged in'}/> : <UserX/>}
        <div className={clsx('popover', { open: isPopoverOpen })}>
          <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
              <path d="M8 0L14.9282 12H1.0718L8 0Z" fill="currentColor"/>
            </g>
            <defs>
              <clipPath id="clip0">
                <rect width="16" height="12" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          <UserLoggedInPopover/>
        </div>
      </div>
      {/* eslint-enable */}
    </>
  )
}

const Header = ({ fixedHeaderState }) => {
  // TODO: Not properly implemented, should use version from releases site (see Releases Context)
  const [ version, set ] = React.useState('2110.1')
  const searchMatch = useMatch(`${process.env.SITE_PATH_PREFIX}/search`)
  const [ isUserLoggedIn, triggerLogin ] = useIsUserLoggedin()

  const secondHeader = React.useRef()

  const handleChange = (event) => {
    set(event.target.value)
  }

  const config = useConfig()

  const matchRoot = useMatch(`${process.env.SITE_PATH_PREFIX}/`)

  return (
    <StyledHeader>
      <div className={'first'}>
        <Visible className={'left'} md lg xl>
          <div className={'logo'}>
            <Link to={'/'}>
              <Hidden md><Logo/></Hidden>
              <Visible md><LogoSmall/></Visible>
            </Link>
          </div>
          <div className={'navigation'}>
            <ul>
              <li><Link to={'/'} activeClassName={'active'}>Documentation</Link></li>
              <li><a href={linkToDownloads(12)}>Downloads</a></li>
              <li><a href={'https://support.coremedia.com'}>Support</a></li>
              <li><a href={'https://www.coremedia.com/training'}>Training</a></li>
              <li><a href={'https://www.coremedia.com'}>CoreMedia.com</a></li>
            </ul>
          </div>
        </Visible>

        <Burger className={'d-md-none d-lg-none d-xl-none'}>
          <ul>
            <li><Link to={'/how-to-guides/'} partiallyActive>How-to Guides</Link></li>
            {/*<li><Link to={'/concepts/'} partiallyActive>Concepts</Link></li>*/}
            <li><a href={linkToDocumentation(12, version)}>Reference</a></li>
          </ul>
          <ul>
            <li><Link to={'/'}>Documentation</Link></li>
            <li><a href={linkToDownloads(12)}>Downloads</a></li>
            <li><a href={'https://support.coremedia.com'}>Support</a></li>
            <li><a href={'https://www.coremedia.com/training'}>Training</a></li>
            <li><a href={'https://www.coremedia.com'}>CoreMedia.com</a></li>
          </ul>
        </Burger>
        <Hidden md lg xl>
          <Link to={'/'}><LogoSmall/></Link>
        </Hidden>
        <LoginState
          userLoggedIn={isUserLoggedIn}
          triggerLogin={triggerLogin}
          loginEnabled={config.login_enabled}/>
      </div>
      <div className={clsx('second', { hidden: matchRoot })} ref={secondHeader}>
        <div className={clsx('left', 'full-width')}>
          {config.search_enabled && !searchMatch && (
            <SearchField type={'header'}/>
          )}
        </div>
        <div className={'right'}>
          <Visible md lg xl>
            {config.version_selector_enabled && (
              <div className={'select-wrapper'}>
                {/* eslint-disable jsx-a11y/no-onchange */}
                <select className={'version-selector'} onChange={handleChange} value={version}>
                  <option value={'2110.1'}>v2110.1</option>
                </select>
                {/* eslint-enable jsx-a11y/no-onchange */}
                <ChevronDown/>
              </div>
            )}
            <ul className={'buttons'}>
              <li><Link to={'/how-to-guides/'} partiallyActive>How-to Guides</Link></li>
              <li><Link to={'/discover/'} partiallyActive>Discover</Link></li>
              <li><Link to={'/reference-materials/'} partiallyActive>Reference Materials</Link></li>
            </ul>
          </Visible>
        </div>
      </div>
    </StyledHeader>
  )
}

export default Header

export { linkToDownloads, linkToDocumentation }
