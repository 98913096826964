import {useStaticQuery, graphql} from "gatsby"

const useNavigationData = () => {
  const data = useStaticQuery(graphql`
      query {
          allMdx {
              edges {
                  node {
                      id
                      __typename
                      slug
                      fileAbsolutePath
                      frontmatter {
                          title
                          abstract
                          order
                          versions
                          version
                      }
                      parent {
                          ... on File {
                              sourceInstanceName
                          }
                      }
                  }
              }
          }
          allAsciidoc(filter: {pageAttributes: {showinnavigation: {ne: "false"}}}) {
              edges {
                  node {
                      id
                      __typename
                      document {
                          title
                      }
                      fields {
                          slug
                      }
                      pageAttributes {
                          order
                          versions
                          version
                      }
                  }
              }
          }
          allDirectory(filter: {dir: {regex: "/.*/src/pages.*/"}}) {
              nodes {
                  relativePath
                  id
                  name
                  __typename
              }
          }
        allFile(filter: { relativeDirectory: { eq: "services/event-hub-service" }, extension: { eq: "js" } }) {
          edges {
            node {
              id
              name
              relativePath
              absolutePath
              __typename
            }
          }
        }
      }
  `)
  return data;
}

export default useNavigationData;
