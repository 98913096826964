import React from 'react'
import styled from 'styled-components'
import {ChevronRight, ChevronLeft, X} from "react-feather";
import clsx from "clsx";

const Style = styled.div`

  .fab {
    position: fixed;
    bottom: 24px;
    right: 24px;
    background-color: #6FC3B8;
    border: none;
    border-radius: 28px;
    width: 56px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-size: 24px;
    z-index: 500;
    cursor: pointer;

    box-shadow: 0 0.8px 1.4px rgba(0, 0, 0, 0.059),
    0 2px 3.5px rgba(0, 0, 0, 0.085),
    0 3.8px 6.5px rgba(0, 0, 0, 0.105),
    0 6.7px 11.6px rgba(0, 0, 0, 0.125),
    0 12.5px 21.7px rgba(0, 0, 0, 0.151),
    0 30px 52px rgba(0, 0, 0, 0.21);

    svg {
      width: 24px;
      height: 24px;
      min-height: 24px;
      min-width: 24px;

      &:first-child {
        margin-right: -3px;
      }

      &:last-child {
        margin-left: -3px
      }
    }
  }

  .full-screen-container {
    position: fixed;
    top: 0;
    left: -100vw;
    width: 100vw;
    height: 100vh;
    background-color: #EEEEEE;
    z-index: 101;
    transition: left ease-in-out .3s;
    padding: 24px;
    overflow-y: auto;

    @supports (backdrop-filter: blur(40px)) or (-webkit-backdrop-filter: blur(40px)) {
      background-color: #EEEEEE66;
      backdrop-filter: blur(40px);
      -webkit-backdrop-filter: blur(40px);
    }

    .close {
      position: absolute;
      top: 24px;
      right: 24px;
      color: #868686;
      cursor: pointer;
    }
  }

  &.open {
    .full-screen-container {
      left: 0;
    }
  }

`

const FullScreenContainer = ({ children, closeOnEveryClick = false }) => {

  const [open, set] = React.useState(false)
  const container = React.useRef()

  const handleClick = (event) => {
    if (closeOnEveryClick) {
      set(v => !v)
      return
    }
    if (container.current === event.target || 'A' === event.target.tagName) {
      set(v => !v)
    }
  }

  const handleClose = () => {
    if (!closeOnEveryClick) set(false)
  }

  // Prevent body scrolling when overlay is open
  React.useEffect(() => {
    document.documentElement.style.overflow = open ? 'hidden' : 'auto'
  }, [open])

  return (
    <Style className={clsx({open})}>
      {/* eslint-disable */}
      <div className={'full-screen-container'} onClick={handleClick} ref={container}>
      {/* eslint-enable */}
        <div className={'close'}><X onClick={handleClose}/></div>
        {children}
      </div>
      <button className={'fab'} onClick={() => set(v => !v)}>
        <ChevronLeft/><ChevronRight/>
      </button>
    </Style>
  )
}

export default FullScreenContainer
