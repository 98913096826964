import innertext from 'react-innertext'

const { getMajorCommerceVersion } = require('./commerce-version-mapping.js');

const linkToDocumentation = (major, version = 'overview') => `https://documentation.coremedia.com/cmcc-${major}/${version}/`

const linkToDownloads = (major, version = undefined) => version ? `https://releases.coremedia.com/cmcc-${major}/overview/distributions/${version}/` : `https://releases.coremedia.com/cmcc-${major}/overview/`

const linkToWhatsNew = (major, version = '2104', ampVersion) => `https://releases.coremedia.com/cmcc-${major}/artifacts/whats-new/CoreMedia%20-%20What's%20New%20in%20${version}.${ampVersion}.pdf`

const linkToReleaseNotes = (major, aep = '2104', amp = '1') => `https://documentation.coremedia.com/cmcc-${major}/artifacts/${aep}/release-notes-webhelp/content/Release${aep}.${amp}.html`

const headlineToId = (headline) => {
  const text = typeof headline === 'string' ? headline : innertext(headline)
  return transformToId(text)
}

const transformToId = (text) => text.toLowerCase().replaceAll(' ', '-')

const replaceVersionInUriTemplate = (majorVersion, aep, amp, template) => {
  let resultTemplate = template.replaceAll('{majorCommerceVersion}', getMajorCommerceVersion(aep));
  if (majorVersion) {
    majorVersion = "cmcc-" + majorVersion.substr(4, 2);
    resultTemplate = resultTemplate.replaceAll('{majorVersion}', majorVersion).replaceAll('{aep}', aep).replaceAll('{amp}', amp).replaceAll('{majorVersionNumber}', majorVersion.substr(5, 2));
  } else {
    resultTemplate = resultTemplate.replaceAll('{aep}', aep).replaceAll('{amp}', amp);
  }
  return resultTemplate;
}

export {
  linkToDownloads, linkToDocumentation, linkToWhatsNew, headlineToId, linkToReleaseNotes, replaceVersionInUriTemplate, transformToId
}
