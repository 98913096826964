import React, { useState } from 'react'
import useMeasure from '../../utils/use-measure'
import PropTypes from 'prop-types'
import {animated, useSpring} from 'react-spring'
import styled from 'styled-components'
import { ChevronRight } from 'react-feather'

const H3 = styled.h3`
  position: relative;
  cursor: pointer;
  user-select: none;
  text-transform: uppercase;
  font-size: 14px;
  color: #3F3F3F;
  font-weight: 300;

  :nth-child(1n+2) {
    padding-top: 2rem;
  }
`;

const HideableDiv = styled(animated.div)`
  overflow: hidden;
  margin-left: -7px;
  will-change: height;
`;

const StyledChevron = styled(ChevronRight)`
  position: relative;
  top: 7px;
  transform: rotate(0deg);
  width: 24px;
  height: 24px;
  transition: .2s;
`;

const AnimatedAccordion = ({ title, description, children, open }) => {
  const [toggle, set] = useState(open);
  const [bind, { height }] = useMeasure()
  const props = useSpring({ minHeight: height })

  return (
    <>
      <H3 onClick={() => set(toggle => !toggle)}>
        <StyledChevron style={{ transform: `rotate(${toggle ? '90deg' : '0deg'})` }}/>&nbsp;{title}
      </H3>
      {description && <div>{description}</div>}
      <HideableDiv style={props}>
        <div {...bind}>{toggle && children}</div>
      </HideableDiv>
    </>
  )
}

AnimatedAccordion.propTypes = {
  /** Title of the accordion. Clicking it will open and close the accordion */
  title: PropTypes.string.isRequired,
  /** Description will be shown below the title */
  description: PropTypes.string,
  /** Content of the accordion that can be shown/hidden */
  children: PropTypes.node
}

export default AnimatedAccordion;
